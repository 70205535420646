<template>
  <div>
    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @clickedShowDetailModal="clickedShowDetailModal" />
    <add-edit-class :is-add-edit-class-sidebar-active.sync="isAddEditClassSidebarActive" :Editclassdata="Editclassdata"
      :Editclassname="Editclassname" :Editclassimg="Editclassimg" @clickeditclassdata="clickeditclassdata" />
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewUserSidebarActive = true" v-if="removeButtons == true">
                <span class="text-nowrap">Add Grade</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-card no-body class="mb-0">
        <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>No.</b-th>
              <b-th>Grade Image</b-th>
              <b-th>Grade Name</b-th>
              <b-th v-if="removeButtons == true">Public Access</b-th>
              <b-th v-if="removeButtons == true">Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(grade, index) in getClass()">
              <b-tr :key="index">
                <b-td>{{ index + 1 }}</b-td>
                <b-td>
                  <div v-if="grade.classImg">
                    <b-link :to="{
      path: '/classes/subjects/' + grade._id,
    }">
                      <img class="mr-1" style="width: 100px; object-fit: contain" :src="grade.classImg" />
                    </b-link>
                  </div>
                </b-td>
                <b-link :to="{
      path: '/classes/subjects/' + grade._id,
    }">
                  <b-td>
                    <div>
                      {{ grade.className }}
                    </div>
                  </b-td>
                </b-link>
                <b-td v-if="removeButtons == true">
                  <b-form-checkbox v-model="grade.publicAccess" v-on:change="statusChangePublicAccess(grade)" switch
                    size="lg"></b-form-checkbox>


                </b-td>
                <b-td v-if="removeButtons == true">
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item @click="activeEditClassSidebar(
      grade._id,
      grade.className,
      grade.classImg
    )
      ">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="activeDeleteClassSidebar(grade._id)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { BBreadcrumb } from "bootstrap-vue";
import UserListAddNew from "./app/list/users-list/UserListAddNew";
import AddEditClass from "./app/list/users-list/AddEditClass.vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BBreadcrumb,
    UserListAddNew,
    AddEditClass,
    BCard,
    BRow,
    ToastificationContent,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BFormCheckbox,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      Editclassdata: "",
      Editclassname: "",
      isAddNewUserSidebarActive: false,
      isAddEditClassSidebarActive: false,
      searchQuery: "",
      table_data: {},
      Editclassimg: "",
      levelidedit: "",
      Editlevel: "",
      EditLevelname: "",
      EditNoofquestion: "",
      AddSubcategoryid: "",
      deletelevel: { delete_id: "" },
      perPage: 5,
      perPageOptions: [10, 25, 50, 100],
      routedata: this.$route.params.subcatId,
      alertData: "",
      toastMessage: "",
      deleteclassdata: {
        id: "",
      },
      removeButtons: "",
    };
  },
  mounted() {
    this.removeButtons = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "manage_classes");
  },
  created() {
    this.gettabledata();
  },
  methods: {
    statusChangePublicAccess(classes) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/class/public-access/update",
          {
            _id: classes._id,
            publicAccess: classes.publicAccess
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.gettabledata();
        });
    },
    activeDeleteClassSidebar: function (classdelete) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteclassdata.id = classdelete;
          let token = localStorage.getItem("_t");
          axios
            .post(
              process.env.VUE_APP_API_URL + "/grade/delete",
              this.deleteclassdata,
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((response) => {
              if (response.data == "Class not delete") {
                this.alertData =
                  "Grade not delete.Please delete all the Subject first";
                this.showToasted("danger");
              } else {
                this.alertData = "Grade deleted";
                this.showToasted("danger");
                this.gettabledata();
              }
            });
        }
      });
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    classAdded(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Class Added",
          variant,
        },
      });
    },
    classAddedRejected(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "class not added",
          variant,
        },
      });
    },
    toastNotification(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.toastMessage,
          variant,
        },
      });
    },
    activeEditClassSidebar(edit_class, edit_name, edit_img) {
      this.isAddEditClassSidebarActive = true;
      this.Editclassdata = edit_class;
      this.Editclassname = edit_name;
      this.Editclassimg = edit_img;
    },
    clickeditclassdata: function (edit_class_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/grade/edit", edit_class_data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          this.toastMessage = "Grade Successfully Edit";
          this.toastNotification("success");
          this.gettabledata();
        })
        .catch(function (err) {
          this.toastMessage = "Try Again Grade Not Edit";
          this.toastNotification("danger");
        });
    },
    clickedShowDetailModal: function (class_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/grade/add", class_data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          if (response.data.message == "Grade created successfully") {
            this.classAdded("success");
            this.gettabledata();
          } else {
            this.classAddedRejected("danger");
          }
        });
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/grade/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;
        });
    },
    getClass() {
      if (this.searchQuery.trim().length > 0) {
        return this.table_data.filter((item) => {
          if (item.className) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.className.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

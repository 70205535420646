<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Add Grade</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <zape-gallery-model :props_hideShowModel="showImageModel" @modelClose="modelClose"
        @saveCategoryImage="saveCategoryImage" />
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <validation-provider #default="validationContext" name="Grade Name" rules="required">
            <b-form-group label="Grade Name" label-for="Grade Name">
              <b-form-input id="Grade-Name" v-model="ClassData.class_name" autofocus
                :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <br />
          <img style="width: 200px; height: 150px" :src="ClassData.class_img" alt="grade" />
          <br />
          <br />
          <div class="d-flex gap-2">
            <b-button variant="primary" @click="showgalleryimage">
              <span class="d-none d-sm-inline">Image gallery</span>
            </b-button>
            <upload-image @saveUploadImage="saveUploadImage" />
          </div>
          <div class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" @click="hide"
              :disabled="submitbutton">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" style="margin-left: 10px"
              variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    ZapeGalleryModel: () => import("../../../components/Model/ZapeGalleryModel.vue"),
    UploadImage: () => import("../../../components/Model/UploadImage.vue"),
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ClassData: {
        class_name: "",
        class_img:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
      },
      showImageModel: false,
    };
  },
  computed: {
    submitbutton() {
      return !(
        this.ClassData.class_name.length > 0 &&
        this.ClassData.class_img.length > 0 &&
        this.ClassData.class_img !== "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
      );
    },
  },
  methods: {
    saveUploadImage(url) {
      this.ClassData.class_img = url;
    },
    modelClose(modelState) {
      this.showImageModel = modelState;
    },
    saveCategoryImage(categoryImage) {
      this.ClassData.class_img = categoryImage;
    },
    showgalleryimage() {
      this.showImageModel = true;
    },
    getValidationState({ validated, valid = null }) {
      return validated ? valid : null;
    },
    resetForm() {
      this.ClassData = {
        class_name: "",
        class_img:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
      };
      this.validated = false;
    },
    onSubmit() {
      this.$emit("clickedShowDetailModal", this.ClassData);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
